import React from 'react'

class Section extends React.Component {
  render () {
    return (
      <div className={this.props.imgBg}>
        <div className="fix">
          <h2>{this.props.content.title}</h2>
          <p>{this.props.content.text}</p>
        </div> 
      </div>
    )
  }
}

export default Section