import React from 'react'
import Section from '../components/Section'

class NotFound extends React.Component {  
  error = {
    title: 'Página no encontrada',
    text:'Lo sentimos pero el contenido que estas buscando no existe, revisa la direccion desde la cual estas ingresando o visita el INICIO para navegar dentro del sitio. Si crees que es un error, comunicate con nosotros al teléfono 3105041968.'
  }
  
  render () {
    window.scrollTo(0,0)
    return (
      <div>
        <Section content={this.error} imgBg="matrix dark-mode"/>
      </div>
    )
  }
}

export default NotFound