import React from 'react'

class Course extends React.Component {
  render () {
    return (
      <div className="matrix matrix-program dark-mode cursos">
        <div className="fix">
          <h2>Metodología</h2>
          <p>Más de 30 años de experiencia nos han permitido diseñar un moderno y novedoso método de enseñanza interactivo - conversacional, basado en el aprendizaje mediante el logro de objetivos definidos, los cuales se determinan en la entrevista personalizada inicial que realizamos con el alumno. Esto nos permite desarollar las competencias de comunicación oral, escrita y de compresión de lectura que necesita el profesional de hoy.</p>
          <div className="courses">
            <ul>
              <li>
                <h4>BÁSICO INDIVIDUAL</h4>
                <p>2 Clases semanales + 2 tutorías / mes</p>
              </li>
              <li>
                <h4>STANDARD</h4>
                <p>10 Clases presenciales + 2 tutorías / mes</p>
              </li>
              <li>
                <h4>STANDARD PLUS</h4>
                <p>12 Clases presenciales + 2 tutorías / mes</p>
              </li>
              <li>
                <h4>GOLD</h4>
                <p>16 Clases presenciales + 2 tutorías / mes</p>
              </li>
            </ul>
            <p>Las clases de cada plan se dictan en ciclos mensuales; los horarios son agendados de acuerdo a las necesidades del alumno y serán dictadas en nuestras instalaciones, a domicilio o de manera virtual. Cursos de inmersión total disponibles, consultenos llamando al 316 419 3298.</p>
          </div>
        </div> 
      </div>
    )
  }
}

export default Course