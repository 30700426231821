import React from 'react'
import Cover from '../components/Cover'
import Programs from '../components/Programs'
import Featured from '../components/Featured'
import Contact from '../components/Contact'

import user from '../images/user.png'
import img1 from '../images/icon-1.png'
import img2 from '../images/icon-2.png'
import img3 from '../images/icon-3.png'
import img4 from '../images/icon-4.png'

class Programa extends React.Component {
  state = {
    cover:{
      title: 'Inglés virtual personalizado para Ejecutivos',
      text: 'Preparamos para exámenes de certificación'
    },
    data:[{
      id: 'pro1',
      img: img1,
      title: 'Inglés para Ejecutivos',
      text: 'Programa con énfasis en el vocabulario y estructuras utilizadas en el inglés para negocios internacionales y mercadeo.  Orientado a grupos de 1 a 6 alumnos. Horarios fijos o variables según las necesidades del grupo.'
    },
    {
      id: 'pro3',
      img: img3,
      title: 'Ingles Especializado',
      text: 'Personalizado o grupal (máximo 5 alumnos) - Cursos en áreas específicas según la necesidad de los alumnos: aviación, mercadeo, profesionales de la salud, finanzas, producción, control de calidad, computación y sistemas, talento humano, investigación, desarrollo e ingenierias.'
    },
    {
      id: 'pro2',
      img: img2,
      title: 'Inglés para propósitos Especiales',
      text: 'Personalizado - Preparación para entrevistas laborales en multinacionales, para presentaciones corporativas, conferencias y congresos internacionales.'
    },
    {
      id: 'pro4',
      img: img4,
      title: 'Inglés para exámenes Internacionales',
      text: 'Preparación para IELTS / TOEFL (Para el ingreso a universidades del exterior), MICHIGAN, OACI/TEA (Test de inglés para aviación).'
    }],
    testimonial:{
      name: 'William Morales',
      profesion: 'Gerente de Operaciones / Colombina',
      url: 'https://www.linkedin.com/in/jorge-william-morales-vargas-0b28181a/',
      img: user,
      text: 'Mi experiencia con OWEN ENGLISH ha sido positiva y enriquecedora dado que el profesor Owen tiene un dominio profundo en el Idioma a nivel Gramatical y Oral e igualmente a través de su experiencia profesional ha compilado y desarrollado su propio método de enseñanza, el cual le aporta al estudiante suficientes herramientas para aprender el idioma en forma sencilla, clara y metodológica.',
      text2: 'Complementariamente y a nivel profesional el profesor Owen maneja un amplio espectro del conocimiento cultural del Idioma, lo que hace que la relación Estudiante – Profesor genere mayor valor agregado a la clase.'
    }
  }
  
  render () {
    window.scrollTo(0,0)
    return (
      <div>
        <Cover content={this.state.cover} imgBg="matrix dark-mode programa"/>
        <Programs content={this.state.intro} pro={this.state.data} />
        <Featured content={this.state.testimonial} />
        <Contact />
      </div>
    )
  }
}

export default Programa