import React from 'react'
import Program from './Program'

class Programs extends React.Component {
  render () {
    return (
      <div className="matrix matrix-program dark-mode tipos">
        <div className="fix program-list">
          {this.props.pro.map((pro) => {
            return (
              <React.Fragment key={pro.id}>
                <Program 
                  title={pro.title}
                  text={pro.text}
                  img={pro.img}
                />
              </React.Fragment>
            )
          })}
        </div> 
      </div>
    )
  }
}

export default Programs