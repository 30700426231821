import React from 'react'

class Cover extends React.Component {
  render () {
    return (
      <div className={this.props.imgBg}>
        <div className="fix">
          <h1>{this.props.content.title}</h1>
          <h2>{this.props.content.text}</h2>
        </div> 
      </div>
    )
  }
}

export default Cover