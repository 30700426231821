import React from 'react'
import Logo from '../images/linkedin.png'

class Featured extends React.Component {
  render () {
    return (
      <div className="matrix dark-mode portfolio">
        <div className="fix">
          <div>
            <img src={this.props.content.img} alt={this.props.content.name} width="100" height="100"></img>
            <a href={this.props.content.url} target="blank">
              <img src={Logo} alt={this.props.content.name}></img>
            </a>
          </div>
          <hgroup>
            <h1>{this.props.content.name}</h1>
            <h2 className="secondary">{this.props.content.profesion}</h2>
          </hgroup>
          <p>{this.props.content.text}</p>
          <p>{this.props.content.text2}</p>
        </div> 
      </div>
    )
  }
}

export default Featured