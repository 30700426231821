import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Inicio from './pages/Inicio'
import Programa from './pages/Programa'
import Plan from './pages/Plan'
import NotFound from './pages/NotFound'
import Layout from './Layout'
import './App.sass';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route exact path="/programas" component={Programa} />
            <Route exact path="/planes" component={Plan} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
