import React from 'react'
import { Link, NavLink } from 'react-router-dom'

class Navbar extends React.Component {
  render () {
    return (
      <React.Fragment>
        <header>
          <div className="logo">
            <Link to="/">
              <span>OWEN</span> ENGLISH <small>PRO</small>
            </Link>
          </div>
          <nav>
            <ul>
              <li><NavLink exact to="/">Inicio</NavLink></li>
              <li><NavLink exact to="/programas">Programas</NavLink></li>
              <li><NavLink exact to="/planes">Planes</NavLink></li>
            </ul>
          </nav>
        </header>
      </React.Fragment>
    )
  }
}

export default Navbar