import React from 'react'

class Program extends React.Component {
  render () {
    return (
      <div className="program">
        <img src={this.props.img} alt={this.props.title} width="100" height="100"></img>
        <h2>{this.props.title}</h2>
        <p>{this.props.text}</p>
      </div>
    )
  }
}

export default Program