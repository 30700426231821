import React from 'react'
import Cover from '../components/Cover'
import Section from '../components/Section'
import Featured from '../components/Featured'
import Contact from '../components/Contact'

import natha from '../images/nathaly.png'

class Inicio extends React.Component {
  state = {
    cover:{
      title: 'Profesionales en la enseñanza de Inglés',
      text: 'Profesores Nativos Norteamericanos expertos en enseñanza virtual'
    },
    intro:{
      title: 'Experimente un aprendizaje verdaderamente personalizado',
      text: 'Profesores nativos norteamericanos asegurarán el logro de sus objetivos de aprendizaje, mediante el uso de los más altos estándares pedagógicos modernos.Owen English ofrece una variedad de programas ajustados a las necesidades individuales de cada alumno. Aprenda Ingles en su empresa, en su casa, en nuestra sede o con nuestro novedoso sistema virtual: Full English Contact On line.'
    },
    natha:{
      name: 'Nathaly Mesa',
      profesion: 'Node.js Developer / Globant',
      url: 'https://www.linkedin.com/in/nathaly-mesa-garcia-09401558/',
      img: natha,
      title: 'Experimente un aprendizaje verdaderamente personalizado',
      text: 'He logrado afianzar mis habilidades de speaking a un nivel que me permitió junto con mis habilidades profesionales ingresar a una multinacional enfocada al trabajo para clientes en el extranjero que requieren soporte y reuniones en un ingles conversacional fluido.',
      text2: 'Lo mejor de mi experiencia en Owen English fue su método de enseñanza, ya que me permitió empezar a desechar los errores comunes que se realizan a la hora de hablar con una persona originaria estadounidense, expresándome correctamente y con seguridad.'
    }
  }
  
  render () {
    window.scrollTo(0,0)
    return (
      <div>
        <Cover content={this.state.cover} imgBg="matrix dark-mode inicio"/>
        <Section content={this.state.intro} imgBg="matrix dark-mode intro-img"/>
        <Featured content={this.state.natha} />
        <Contact />
      </div>
    )
  }
}

export default Inicio
