import React from 'react'
import Cover from '../components/Cover'
import Course from '../components/Course'
import Featured from '../components/Featured'
import Contact from '../components/Contact'

import user from '../images/user.png'

class Plan extends React.Component {
  state = {
    cover:{
      title: 'Ingles como segundo Idioma.',
      text: 'Aprende con instructores expertos Norteamericanos especializados en la enseñanza de Ingles'
    },
    testimonial:{
      name: 'Margarita María Andrade',
      profesion: 'PhD. Ing Alimentos / Profesora Universidad Nacional',
      url: 'http://scienti.colciencias.gov.co:8081/cvlac/visualizador/generarCurriculoCv.do?cod_rh=0001473749',
      img: user,
      text: 'Owen English dispone de profesionales con excelentes estrategias en la enseñanza del inglés.',
      text2: 'Su metodología ha impactado positivamente en mi comunicación oral y escrita del idioma, específicamente en la publicación de artículos científicos y preparación de ponencias internacionales.'
    }
  }
  
  render () {
    window.scrollTo(0,0)
    return (
      <div>
        <Cover content={this.state.cover} imgBg="matrix dark-mode plan"/>
        <Course />
        <Featured content={this.state.testimonial} />
        <Contact />
      </div>
    )
  }
}

export default Plan
