import React from 'react'
import Icon from '../images/icon-5.png'

class Contact extends React.Component {
  render () {
    return (
      <div className="matrix dark-mode contact">
        <div className="fix">
          <img src={Icon} alt="Owen WhatsApp" width="100" height="100"></img>
          <p>LLÁMANOS</p>
          <h1>310 504 1968</h1>
          <p>Comunícate con nosotros para ampliar o conocer más información de nuestros planes y servicios. Nuestras instalaciones estan ubicadas cerca del Parque de la Flora en Cali.</p>
          <p>Puedes contactarnos por WhatsApp a nuestro número celular haciendo click <a href="https://wa.me/573105041968">AQUÍ</a> o tambien <a href="mailto:owentutor970@gmail.com?Subject=Quiero%20mejorar%20mi%20Ingles">ENVIARNOS UN EMAIL</a>.</p>
        </div> 
      </div>
    )
  }
}

export default Contact